<template>
    <div>
        <multiselect
            :placeholder="$t('select')"
             v-model="selected"
            :options="options"
            :class="validateError != '' ? 'box-border-color':''"
            @input="handleInput"
            label="text"
            track-by="value"
            :multiple="false"
            :select-label="''"
            :selected-label="''"
            :deselect-label="''"
            :searchable="true">
            <span slot="noOptions">
                {{ $t('no_options') }}
            </span>
            <span slot="noResult">
                {{ $t('no_result') }}
            </span>
        </multiselect>
        <span class="invalid-feedback-custom"
              v-show="validateError"
              v-html="validateError">
        </span>
    </div>
</template>

<script>
    import VirtualPosService from "@/services/VirtualPosService";
    import setSelected from "@/helpers/setSelected";

    export default {
        props: {
            value: {
                default: null
            },
            validateError: {
                type: String,
                default: ''
            }
        },
        watch: {
            value: function (newValue) {
                if (this.options.length > 0) {
                    this.selected = this.options.filter(c => c.value == newValue);
                }
            },
        },
        data() {
            return {
                selected: this.value,
                options: [
                    {value: null, text: this.$t('select')}
                ]
            }
        },
        methods: {
            setSelected: setSelected,
            getData() {
                VirtualPosService.getAll({params:{limit:-1}})
                              .then(response => {
                                  const data = response.data.data;
                                  data.map(item => {
                                      item.value=item.code
                                      item.text=this.getLocaleText(item, 'name')
                                      this.options.push(item);
                                  });
                                  this.selected = this.setSelected(this.value, this.options, false);
                              });
            },
            handleInput(event) {
                if (event && event.value) {
                    this.$emit('input', event.value);
                }
                else {
                    this.$emit('input', null);
                }
            },
            getText(id){
                let found=null
                this.options.forEach((item)=>{
                    if(item.value===id){
                        found = item.text;
                    }
                });
                return found;
            },
            getItem(id){
                let found=null
                this.options.forEach((item)=>{
                    if(item.value===id){
                        found = item;
                    }
                });
                return found;
            }
        },
        created() {
            this.getData();
        }
    };
</script>
